import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Template = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout location={location} title={siteTitle}>
        <Seo title={frontmatter.title} />

        <article className="blogPost">
        <header>
          <h1>
            {frontmatter.title}
          </h1>
        </header> <div
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: html }}
            />
        </article>
    </Layout>
  )
}

export default Template

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`